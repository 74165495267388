import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0_sass@1.69.7/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0_sass@1.69.7/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/brand/EatHomeButton/EatHomeButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Buttons/IconButton/IconButton.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Buttons/ThemeButton/ThemeButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Footer/Footer.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Nav/NavBar/NavBar.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.9_react-dom@18.2.0_react@18.2.0_sass@1.69.7/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/EatIconsVF.woff2\",\"preload\":true,\"variable\":\"--eat-icons-vf\",\"display\":\"swap\"}],\"variableName\":\"eatIconsVF\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Nav/NavLangMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Nav/NavMainMenu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/wrappers/MainWrapper/MainWrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/store/BackdropProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/store/LanguageProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/abstracts/_variables.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/main.scss")